  
import { useMemo } from 'react'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

function createIsomorphLink() {
  // Apollo에서 캐싱을 위해 __typename 필드를 함께 담아서 데이터를 불러오는데
  // 데이터를 react state에 담았다가 다시 mutation 할 때 __typename이 있으면 GraphQLTypeError 발생하기 때문에
  // cleanTypeName 미들웨어 사용.
  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
      return data;
    });
  });
  
  let link;
  if (typeof window === 'undefined') {
    const { SchemaLink } = require('apollo-link-schema')
    const { schema } = require('../backend/schema');
    link = new SchemaLink({ schema });
  } else {
    const { HttpLink } = require('apollo-link-http')
    link = new HttpLink({
      uri: '/api/graphql',
      credentials: 'same-origin',
    })
  }

  return ApolloLink.from([
    cleanTypeName,
    link,
  ])
}

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createIsomorphLink(),
    cache: new InMemoryCache(),
  })
}

export function initializeApollo(initialState?: NormalizedCacheObject): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState?: NormalizedCacheObject): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}