import { createContext, useContext, useEffect, FunctionComponent, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useRouter } from "next/router";
import { VIEWER_QUERY } from "../graphql";

const AuthContext = createContext<any>({});

export const AuthProvider: FunctionComponent<any> = ({ children }) => {
  const [user, setUser] = useState(null);
  const { data, loading } = useQuery(VIEWER_QUERY);

  useEffect(() => {
    if (loading) return;
    setUser(data.viewer ? data.viewer : false );
  }, [loading]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): any => {
  return useContext(AuthContext)
}

export const useRequireAuth = (): any => {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (auth.user === false) {
      router.push('/app/auth/login')
    }
  }, [auth, router]);

  return auth;
}

export const useGuestOnly = (): any => {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (auth.user) {
      router.push('/app/posts')
    }
  }, [auth, router])

  return auth;
}