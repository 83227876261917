import { CacheProvider } from "@emotion/react";
import { cache } from "@emotion/css";
import { Global, css } from "@emotion/react";
import "../antd-custom.less";
import "codemirror/lib/codemirror.css";
import { useEffect } from "react";
import { initGA, logPageView } from "../analytics";
import Router from "next/router";
import { AppProps } from "next/app";
import { AuthProvider } from "../contexts/UserContext";
import { useApollo } from "../apollo";
import { ApolloProvider } from "@apollo/react-hooks";
// import 'codemirror-one-light-theme/one-light.css'
// import 'codemirror/theme/material.css';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    initGA();
    logPageView();
    Router.events.on("routeChangeComplete", logPageView);
  }, []);

  return (
    <CacheProvider value={cache}>
      <Global
        styles={css`
          html,
          body {
            font-size: 18px;
            color: black;
            padding: 0;
            margin: 0;
            height: 100%;
            line-height: normal;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
          }

          * {
            box-sizing: border-box;
          }

          #__next {
            height: 100%;
          }
        `}
      />
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <Component {...pageProps} />
        </AuthProvider>
      </ApolloProvider>
    </CacheProvider>
  );
}
